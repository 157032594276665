import { Injectable, Output, EventEmitter  } from '@angular/core';
import { environment } from "../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from './_models/user';
import { map } from 'rxjs/operators';


// interface dataObj {
//   responseCode: any;
//   responseMessage: string;
//   responsedata: any;
//   responseValue: any;
//   results: any[];
//   token: any[];
// }


@Injectable({
  providedIn: 'root'
})
export class AdminloginService {
  baseUrl = environment.apiBaseUrl;
  private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

  constructor(private http: HttpClient,
    ) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }


  login(emailID: string, password: string,) {
    return this.http.post<any>(this.baseUrl+`checkLogin`, { emailID, password })
            .pipe(map(user => {
                if (user && user.token) {
                  console.log('Loginuser',emailID+password)
                    // store user details in local storage to keep user logged in
                  localStorage.setItem('currentUser', JSON.stringify(user.result));
                  localStorage.setItem('token', user.token);
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
      // remove user data from local storage for log out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
      this.currentUserSubject.next(null);
  }




}
