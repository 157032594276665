import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { DatePipe } from '@angular/common';

// import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
// import { HttpModule } from '@angular/http';
// import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutes } from './app.routing';


import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { VideoUploadService } from './services/video-upload.service';
import {AdminloginService} from './adminlogin.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { QuranAndScienceComponent } from './pages/quran-and-science/quran-and-science.component';
import { DocumentaryComponent } from './pages/documentary/documentary.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { MatDatetimepickerModule } from '@mat-datetimepicker/core';



@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    QuranAndScienceComponent,
    DocumentaryComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    NgbModule,
    // ReactiveFormsModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      showUnits:false,
      clockwise:false,
      startFromZero:false

    })


  ],
  providers: [
    VideoUploadService,
    AdminloginService,
    DatePipe,
    
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
