import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { VideoUploadService } from "../../services/video-upload.service";
import { VideoUploadService } from 'app/services/video-upload.service';
import { AddVideoModel, Posts } from "app/_models/addVideo";
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { data } from 'jquery';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
    { path: '/table',         title: 'Upload Video',          icon:'nc-cloud-upload-94',    class: '' },
    //{ path: '/maps',          title: 'Upcoming Video',             icon:'nc-cloud-upload-94',    class: '' },
    //{ path: '/typography',    title: 'Episode',      icon:'nc-cloud-upload-94', class: '' },
    //  { path: '/notifications', title: 'Category Master',            icon:'nc-album-2',    class: '' },
    // { path: '/table',         title: 'Sports',            icon:'nc-album-2',    class: '' },
    // { path: '/table',         title: 'News',              icon:'nc-album-2',    class: '' },
    // { path: '/QuranAndScience',    title: 'Quran And Science',      icon:'nc-album-2', class: '' },
    // { path: '/Documentary',    title: 'Documentary',      icon:'nc-album-2', class: '' },
    
    // { path: '/user',          title: 'User Profile',      icon:'nc-single-02',  class: 'active-pro' },
    //  { path: '/upgrade',       title: 'Upgrade to PRO',    icon:'nc-spaceship',  class: 'active-pro' },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public categoryList: any;
    public subcategoryDataList:any;
    public subcategoryVList:any;
    public i:any;
    public id:any;
    IsShowSubCategory: boolean = false;
    constructor(
        private addVideoService: VideoUploadService,
        private router: Router,
        private toastrService: ToastrService,
        private datePipe: DatePipe,
        private sanitizer: DomSanitizer,
        
    ) {}
    
    ngOnInit() {
        //this.getVideosList();
        
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        const categoryListData=window.localStorage.getItem('categoryListData');
        //const categoryListData = window.localStorage.getItem('categoryListData');
        console.log('insideBr',categoryListData);
        this.categoryList=JSON.parse(categoryListData);

    }


    openSubCategory(i,j) {
        var obj = {
          id:j      
        };
        console.log("test storage Local",obj)
         window.localStorage.setItem('subcategoryById', j);
        location.reload();
      }
}
