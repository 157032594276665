export class AddVideoModel {
  id:number;
  title: string;
  date: string;
  videoID:number;
  catID: number;
  catname: string;
  subcatName:string;
  episode:number;
  subcatID:number;
  description: string;
  imagePath: string;
  videoPath: string;
  thumbnailUrl:string;
  type:number;
  season:number;
  youTubeUrl:string;

}

// subcatName,catID
// @videoID,@videoPath,@episode,@type,@youTubeUrl,@thumbnailUrl
export class Posts{
  id:number;
  catID:number;
}
