import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders,HttpEvent,HttpEventType,HttpErrorResponse} from "@angular/common/http";
import { AddVideoModel } from '../_models/addVideo';
import { environment } from "./../../environments/environment";
import { RouterModule, Routes, Router } from "@angular/router";




interface dataObj {
  responseCode: any;
  responseMessage: string;
  responsedata: any;
  responseValue: any;
  results: any;
}


@Injectable({
  providedIn: 'root'

})
export class VideoUploadService {
 baseUrl = environment.apiBaseUrl;
 token:any;

  constructor(private httpClient: HttpClient, private router :Router) {
    this.token = -1;
        }

        isEmpty(value) {
          if (value === "" || value === undefined) {
            return true;
          } else {
            return false;
          }
        }

        isValidToken() {
          if (
            this.token < 0 ||
            this.token === false ||
            this.token !== localStorage.getItem("AUTHTOKEN")
          ) {
            this.token = localStorage.getItem("AUTHTOKEN")
              ? localStorage.getItem("AUTHTOKEN")
              : -1;
          }

          if (!this.isEmpty(this.token) && this.token != -1) {
            return true;
          } else {
            this.router.navigate(["/"]);
            return false;
          }
        }

        setHeaders() {
          let httpHeaders = new HttpHeaders()
            .set("Content-Type", "application/json")
            .set("x-access-token", this.token);
            // .set("async","false");



          let options = {
            headers: httpHeaders,
          };
          return options;
        }

        isEmptyValue(value) {
          if (
            value === "0" ||
            value === "" ||
            value === 0 ||
            value === undefined ||
            value === null
          ) {
            return true;
          } else {
            return false;
          }
        }


        checkLogin(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + `checkLogin`, data);
        }

        logout(data) {
          if (this.isValidToken()) {
            let headers = this.setHeaders();
            return this.httpClient.post<dataObj>(this.baseUrl + `logout`, data,headers);
          }
        }

        saveVideo(saveVideo: AddVideoModel) {
          // if (this.isValidToken()) {
          //   let headers = this.setHeaders();
            return this.httpClient.post<dataObj>(this.baseUrl+`saveVideo`, saveVideo);
          // }
        }
        saveVideoByTitle(saveVideo: AddVideoModel) {
            return this.httpClient.post<dataObj>(this.baseUrl+`uploadVideoByTitle`, saveVideo);
        }      
        saveVideoByEpisode(saveVideo: AddVideoModel) {
            return this.httpClient.post<dataObj>(this.baseUrl+`saveVideoTitleByCatID`, saveVideo);
        }
        updateVideoByEpisode(saveVideo: AddVideoModel) {
          return this.httpClient.post<dataObj>(this.baseUrl+`updateVideoTitleByCatID`, saveVideo);
      }
        getVideoUploadTitle(data) {
          return this.httpClient.post<dataObj>(this.baseUrl+`getUploadVideoTitleDetails`, data);
      }
        saveUpcomingVideo(saveVideo: AddVideoModel) {
          return this.httpClient.post<dataObj>(this.baseUrl+`uploadUpcomingVideo`, saveVideo);
      }
        uploadVideo(image) {
          return this.httpClient.post(this.baseUrl+`saveMultipleFile`, image);
        }

        saveMultipleFile(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + `saveMultipleFile`, data);
        }

        uploadSingleFile(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + `upload`, data);
        }
        
        
        getCategoryList(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + "getCategoryList", data);
        }
        getCategoryNameByID(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + "getAllCategoryByID", data);
        }
        saveCategoryName(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + "saveCategory", data);
        }
        updateCategoryName(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + "updateCategory", data);
        }
        deleteCategoryName(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + "deleteCategory", data);
        }
        getSubCategoryList(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getSubCategoryList", data);
        }
        getAllSubCategoryName(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllSubCategoryList", data);
        }
        getSubCategoryNameByID(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllSubCategoryByID", data);
        }
        saveSubCategoryName(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "saveSubCategory", data);
        }
        updateSubCategoryName(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "updateSubCategory", data);
        }
        deleteSubCategoryName(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "deleteSubCategory", data);
        }
        getAllVideoTitleList(data) {
          return this.httpClient.post<dataObj>(this.baseUrl + "getAllVideoTitle", data);
        }
        countAllVidesList(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "countAllVideo", data);
        }
        VideosList(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getVideos", data);
        }
        SubCatVideosList(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getSubCatVideos", data);
        }
        getAllVideosList(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllVideoTitle", data);
        }
        getAllUpcomingVideosList(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllUpcomingVideo", data);
        }
        getUpcomingVideosByID(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getUpcomingVideoByID", data);
        }
        updateUpcomingVideo(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "updateUpcomingVideo", data);
        }
        deteleUpcomingVideo(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "deleteUpcomingVideo", data);
        }
        deteleUploadVideoTitle(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "deleteUploadVideoTitle", data);
        }

        EditVideoDetails(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllVideoTitleByID", data);
        }

        updateVideo(updateVideo: AddVideoModel) {
            return this.httpClient.post<dataObj>(this.baseUrl+`updateVideo`, updateVideo);

        }
        updateVideoTitle(updateVideo: AddVideoModel) {
          return this.httpClient.post<dataObj>(this.baseUrl+`updateVideoTitleByID`, updateVideo);

      }
        deleteVideoDetails(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "deleteVideo", data);
        }
        getAllContactAndFAQ(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllHelpandContact", data);
        }
        getSMbyID(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getSocialMediaByID", data);
        }
        getContactByID(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getContactByID", data);
        }
        getFAQbyID(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getFAQbyID", data);
        }
        updateSocialMedia(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "updateSocialMedia", data);
        }
        updateFAQ(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "updateFAQ", data);
        }
        updateContact(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "updateContact", data);
        }
        
        deleteFAQ(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "deleteFAQ", data);
        }
        saveFAQ(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "saveFAQ", data);
        }
        saveEpisode(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "saveEpisode", data);
        }
        getAllEpisode(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllEpisode", data);
        }
        getEpisodeByID(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getEpisodeByID", data);
        }
        updateEpisode(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "updateEpisode", data);
        }
        deleteEpisode(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "deleteEpisode", data);
        }
        getAllReview(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getAllReview", data);
        }
        getReviewByID(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "getReviewByID", data);
        }
        updateReview(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "updateReview", data);
        }
        deleteReview(data) {

          return this.httpClient.post<dataObj>(this.baseUrl + "deleteReviewRating", data);
        }



}
