import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { VideoUploadService } from "../services/video-upload.service";

import { ToastrService } from 'ngx-toastr';
import { map } from "jquery";
import * as $ from "jquery";
import { AddVideoModel, Posts } from "app/_models/addVideo";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginUserData: any;
  categoryList: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public addVideoService: VideoUploadService,
    private toastrService: ToastrService
  ) {
    this.loginUserData = {};
  }
  ngOnInit() {
    if (localStorage.getItem('accessToken') == null || localStorage.getItem('emailID') == null || localStorage.getItem('mobileNo') == null) {
      // this.router.navigate(['']);
      this.router.navigate(['']);
    }
  }

  onLogin() {

    if (this.addVideoService.isEmptyValue(this.loginUserData.mobileNo) || this.addVideoService.isEmptyValue(this.loginUserData.password)) {

      this.toastrService.error('Login Error!!');
    } else {

      $('.loading-overlay').addClass('is-active');
      this.addVideoService.checkLogin(this.loginUserData).subscribe((data) => {
        //console.log('ABCLoginDetails')
        $('.loading-overlay').removeClass('is-active');
        if (data.responseCode == 1) {
          var logindetails = data.responseValue[0];
          // localStorage.setItem('AUTHTOKEN',logindetails.accessToken);
          localStorage.setItem('userData', JSON.stringify(data.responseValue[0]));
          localStorage.setItem('accessToken', logindetails.accessToken);
          localStorage.setItem('emailID', logindetails.emailID);
          localStorage.setItem('mobileNo', logindetails.mobileNo);
       
          // this.toastrService.success('Login Successfully');
          console.log('LoginUserDetails',logindetails);
          this.loginUserData = {};
          //catagorylistdata
          var obj = new Posts();
          this.addVideoService.VideosList(obj).subscribe((Videodata) => {
            console.log('AfterLOging first Before SideBarDATA:');
            if (Videodata.responseCode == 1) {
              window.localStorage.setItem('categoryListData', JSON.stringify(Videodata.responseValue[0]));
              this.categoryList = Videodata.responseValue[0]
              console.log('AfterLOging second:', this.categoryList);
              this.router.navigate(['./layouts/admin-layout/admin-layout.module#AdminLayoutModule']);
            }
          })
          
        } else {
          alert(data.responseMessage);
          this.loginUserData = {};
        }


      });


    }
  }

}
