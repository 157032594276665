import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quran-and-science',
  templateUrl: './quran-and-science.component.html',
  styleUrls: ['./quran-and-science.component.css']
})
export class QuranAndScienceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
